/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h3: "h3",
    strong: "strong",
    ol: "ol",
    li: "li",
    p: "p",
    em: "em",
    hr: "hr",
    ul: "ul"
  }, _provideComponents(), props.components), {Boss, Skill, Effect, Warning, Item, Boon, SpecialActionKey} = _components;
  if (!Boon) _missingMdxReference("Boon", true);
  if (!Boss) _missingMdxReference("Boss", true);
  if (!Effect) _missingMdxReference("Effect", true);
  if (!Item) _missingMdxReference("Item", true);
  if (!Skill) _missingMdxReference("Skill", true);
  if (!SpecialActionKey) _missingMdxReference("SpecialActionKey", true);
  if (!Warning) _missingMdxReference("Warning", true);
  return React.createElement(React.Fragment, null, React.createElement(Boss, {
    name: "mama",
    video: "",
    videoCreator: "",
    foodId: "43360",
    utilityId: "50082",
    heal: "signetofvampirism",
    utility1: "wellofsuffering",
    utility2: "wellofdarkness",
    utility3: "signetofspite",
    elite: "lichform",
    weapon1MainAffix: "Berserker",
    weapon1MainType: "Greatsword",
    weapon1MainSigil1: "force",
    weapon1MainSigil2: "Impact",
    weapon1MainInfusion1Id: "37131",
    weapon1MainInfusion2Id: "37131",
    weapon2OffAffix: "Berserker",
    weapon2OffType: "warhorn",
    weapon2OffSigil: "Force",
    weapon2OffInfusionId: "37131",
    weapon2MainAffix: "Berserker",
    weapon2MainType: "axe",
    weapon2MainSigil1: "Impact",
    weapon2MainInfusion1Id: "37131",
    foodId: "43360",
    utilityId: "50082",
    weapon1MainInfusion1Id: "37131",
    weapon1MainInfusion2Id: "37131",
    weapon2OffInfusionId: "37131",
    weapon2MainInfusion1Id: "37131",
    weapon1MainId: "46762",
    weapon1MainSigil1Id: "24615",
    weapon1MainSigil2Id: "24868",
    weapon2MainId: "46759",
    weapon2MainSigil1Id: "24868",
    weapon2OffId: "46777",
    weapon2OffSigilId: "24615",
    healId: "21762",
    utility1Id: "10546",
    utility2Id: "10607",
    utility3Id: "10622",
    eliteId: "10550"
  }), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Precast")), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Precast ", React.createElement(Skill, {
    name: "Summon Bone Minions",
    id: "10541"
  }), ", detonate them in a fire field"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Precast ", React.createElement(Skill, {
    id: "12363",
    id: "12363"
  }), " / Mistfire Wolf, etc (if you have it)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Take ", React.createElement(_components.em, null, "Mistlock Singularity")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Cast ", React.createElement(Skill, {
    name: "Lich Form",
    id: "10550"
  }), " 4 halfway to ", React.createElement(_components.em, null, "MAMA")), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Phase 1")), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Cast your healing signet into the invulnerability"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Grasping Darkness",
    id: "29740"
  }), " (GS 5)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "NIghtfall",
    id: "29855"
  }), " (GS 4)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Place both of your wells"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "enter shroud and use ", React.createElement(Skill, {
    name: "Soul Spiral",
    id: "30504"
  }), " (Shroud 4)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Auto Attack in shroud until ", React.createElement(_components.em, null, "MAMA"), " phases"), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.em, null, "First Add")), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "CC the add if needed"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Use ", React.createElement(Skill, {
    name: "Death Spiral",
    id: "30860"
  }), " (GS 3)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Autoattack until the add reaches 50% health"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Gravedigger",
    id: "30163"
  }), " (GS 2) until the add dies"), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Phase 2")), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Cast your healing signet on ", React.createElement(_components.em, null, "MAMA"), " if it is off cooldown as you run to her"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Use ", React.createElement(Skill, {
    name: "Death Spiral",
    id: "30860"
  }), " (GS 3) (if off cooldown)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Gravedigger",
    id: "30163"
  }), " (GS 2)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Use ", React.createElement(Skill, {
    name: "Grasping Darkness",
    id: "29740"
  }), " (GS 5) and ", React.createElement(Skill, {
    name: "Nightfall",
    id: "29855"
  }), " (GS 4) when they are off cooldown"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Weapon swap"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Ghastly Claws",
    id: "10528"
  }), " (Axe 2)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Use both wells"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Soul Spiral",
    id: "30504"
  }), " (Shroud 4)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Auto attack until ", React.createElement(_components.em, null, "MAMA"), " phases"), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.em, null, "Second Add")), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Wail of Doom",
    id: "10556"
  }), " (Warhorn 4) to help CC"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Use ", React.createElement(Skill, {
    name: "Ghastly Claws",
    id: "10528"
  }), " (Axe 2)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Weapon swap"), "\n"), "\n"), "\n", React.createElement(_components.p, null, "If under 50% health:"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "Use ", React.createElement(Skill, {
    name: "Gravedigger",
    id: "30163"
  }), " (GS 2)"), "\n"), "\n", React.createElement(_components.p, null, "If not under 50% health:"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Use ", React.createElement(Skill, {
    name: "Death Spiral",
    id: "30860"
  }), " (GS 3)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "then ", React.createElement(Skill, {
    name: "Gravedigger",
    id: "30163"
  }), " (GS 2)"), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Phase 3")), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Grasping Darkness",
    id: "29740"
  }), " (GS 5)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "NIghtfall",
    id: "29855"
  }), " (GS 4)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Place both of your wells"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "If ", React.createElement(_components.em, null, "MAMA"), " is broken: ", React.createElement(Skill, {
    name: "Soul Spiral",
    id: "30504"
  }), " (Shroud 4)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "If ", React.createElement(_components.em, null, "MAMA"), " isn't broken:"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Infusing Terror",
    id: "29958"
  }), " (Shroud 3)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Executioners Scythe",
    id: "30557"
  }), " (Shroud 5)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Soul Spiral",
    id: "30504"
  }), " (Shroud 4)"), "\n"), "\n"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Auto attack until ", React.createElement(_components.em, null, "MAMA"), " phases"), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.em, null, "Third Add")), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "CC the add"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Death Spiral",
    id: "30860"
  }), " (GS 3)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Auto Attacks until the add is at 50% health"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Gravedigger",
    id: "30163"
  }), " (GS 2) until the add dies"), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Phase 4")), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Use ", React.createElement(Skill, {
    name: "Signet of Vampirism",
    id: "21762"
  }), " (Heal) when ", React.createElement(_components.em, null, "MAMA"), " jumps"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "CC ", React.createElement(_components.em, null, "MAMA")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Use ", React.createElement(Skill, {
    name: "Grasping Darkness",
    id: "29740"
  }), " (GS 5)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Use ", React.createElement(Skill, {
    name: "Nightfall",
    id: "29855"
  }), " (GS 4)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Weapon swap"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "CC with ", React.createElement(Skill, {
    name: "Wail of Doom",
    id: "10556"
  }), " (Warhorn 4) if still not ", React.createElement(Effect, {
    name: "Exposed"
  })), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Ghastly Claws",
    id: "10528"
  }), " (Axe 2)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Use both wells"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Soul Spiral",
    id: "30504"
  }), " (Shroud 4)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Auto attack until ", React.createElement(_components.em, null, "MAMA"), " dies (if you run out of life force, ", React.createElement(Skill, {
    name: "Gravedigger",
    id: "30163"
  }), " (GS 2))"), "\n"), "\n"), "\n", React.createElement(_components.hr), "\n", React.createElement(Boss, {
    name: "siax",
    video: "",
    videoCreator: "",
    foodId: "43360",
    utilityId: "50082",
    heal: "signetofvampirism",
    utility1: "wellofsuffering",
    utility2: "wellofdarkness",
    utility3: "signetofspite",
    elite: "lichform",
    weapon1MainAffix: "Berserker",
    weapon1MainType: "Greatsword",
    weapon1MainSigil1: "serpentslaying",
    weapon1MainSigil2: "Impact",
    weapon1MainInfusion1Id: "37131",
    weapon1MainInfusion2Id: "37131",
    weapon2OffAffix: "Berserker",
    weapon2OffType: "warhorn",
    weapon2OffSigil: "serpentslaying",
    weapon2OffInfusionId: "37131",
    weapon2MainAffix: "Berserker",
    weapon2MainType: "axe",
    weapon2MainSigil1: "Impact",
    weapon2MainInfusion1Id: "37131",
    foodId: "43360",
    utilityId: "50082",
    weapon1MainInfusion1Id: "37131",
    weapon1MainInfusion2Id: "37131",
    weapon2OffInfusionId: "37131",
    weapon2MainInfusion1Id: "37131",
    weapon1MainId: "46762",
    weapon1MainSigil1Id: "24658",
    weapon1MainSigil2Id: "24868",
    weapon2MainId: "46759",
    weapon2MainSigil1Id: "24868",
    weapon2OffId: "46777",
    weapon2OffSigilId: "24658",
    healId: "21762",
    utility1Id: "10546",
    utility2Id: "10607",
    utility3Id: "10622",
    eliteId: "10550"
  }, React.createElement(Warning, null, React.createElement(_components.p, null, "Remember to equip ", React.createElement(Item, {
    name: "serpentslaying",
    id: "24658"
  }), " + ", React.createElement(Item, {
    name: "impact",
    id: "24868"
  }), " for this encounter!"))), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Precast")), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Precast Staff symbols on the orb that starts the encounter"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Precast ", React.createElement(Skill, {
    name: "Summon Bone Minions",
    id: "10541"
  }), ", detonate them in a fire field"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Precast ", React.createElement(Skill, {
    id: "12363",
    id: "12363"
  }), " / Mistfire Wolf, etc (if you have it)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Take ", React.createElement(_components.em, null, "Mistlock Singularity")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Cast ", React.createElement(Skill, {
    name: "Lich Form",
    id: "10550"
  }), " 4 at the orb before the encounter starts"), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Phase 1")), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Grasping Darkness",
    id: "29740"
  }), " (GS 5)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Nightfall",
    id: "29855"
  }), " (GS 4)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Place both of your wells"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Soul Spiral",
    id: "30504"
  }), " (Shroud 4)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Infusing Terror",
    id: "29958"
  }), " (Shroud 3)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Executioners Scythe",
    id: "30557"
  }), " (Shroud 5)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Shroud auto attack until ", React.createElement(_components.em, null, "Siax"), " phases ", React.createElement(_components.strong, null, "or"), " you are at ~6k Life force"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Use ", React.createElement(Skill, {
    name: "Gravedigger",
    id: "30163"
  }), " (GS 2)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Autoattack until ", React.createElement(_components.em, null, "Siax"), " phases"), "\n"), "\n"), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.em, null, "Split Phase 1")), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Use ", React.createElement(Skill, {
    name: "Death Spiral",
    id: "30860"
  }), " (GS 3)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Auto attack until 50%"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Gravedigger",
    id: "30163"
  }), " (GS 2)"), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Phase 2")), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "If Siax is still invulnerable, when your add dies, cast your healing signet into the invulnerability"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Grasping Darkness",
    id: "29740"
  }), " (GS 5)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Nightfall",
    id: "29855"
  }), " (GS 4)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Place both of your wells"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Soul Spiral",
    id: "30504"
  }), " (Shroud 4)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Shroud auto attack until ", React.createElement(_components.em, null, "Siax"), " phases ", React.createElement(_components.strong, null, "or"), " you are at ~6k Life force"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "Use ", React.createElement(Skill, {
    name: "Gravedigger",
    id: "30163"
  }), " (GS 2) until phases"), "\n"), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.em, null, "Split Phase 2")), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Use ", React.createElement(Skill, {
    name: "Death Spiral",
    id: "30860"
  }), " (GS 3)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Auto attack until 50%"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Use ", React.createElement(Skill, {
    name: "Gravedigger",
    id: "30163"
  }), " (GS 2)"), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Phase 3")), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "If Siax is still invulnerable, when your add dies, cast your healing signet into the invulnerability"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Use both wells when the fight starts"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Grasping Darkness",
    id: "29740"
  }), " (GS 5)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Nightfall",
    id: "29855"
  }), " (GS 4)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Soul Spiral",
    id: "30504"
  }), " (Shroud 4)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Shroud auto attack until you have no life force left"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "(If ", React.createElement(Skill, {
    name: "Soul Spiral",
    id: "30504"
  }), " comes off colldown, use it)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Use ", React.createElement(Skill, {
    name: "Gravedigger",
    id: "30163"
  }), " (GS 2) until ", React.createElement(_components.em, null, "Siax"), " dies"), "\n"), "\n"), "\n"), "\n"), "\n", React.createElement(_components.hr), "\n", React.createElement(Boss, {
    name: "ensolyss",
    video: "",
    videoCreator: "",
    foodId: "91805",
    utilityId: "50082",
    heal: "signetofvampirism",
    utility1: "wellofsuffering",
    utility2: "wellofdarkness",
    utility3: "signetofspite",
    elite: "lichform",
    weapon1MainAffix: "Berserker",
    weapon1MainType: "Greatsword",
    weapon1MainSigil1: "serpentslaying",
    weapon1MainSigil2: "Impact",
    weapon1MainInfusion1Id: "37131",
    weapon1MainInfusion2Id: "37131",
    weapon2OffAffix: "Berserker",
    weapon2OffType: "warhorn",
    weapon2OffSigil: "serpentslaying",
    weapon2OffInfusionId: "37131",
    weapon2MainAffix: "Berserker",
    weapon2MainType: "axe",
    weapon2MainSigil1: "Impact",
    weapon2MainInfusion1Id: "37131",
    foodId: "91805",
    utilityId: "50082",
    weapon1MainInfusion1Id: "37131",
    weapon1MainInfusion2Id: "37131",
    weapon2OffInfusionId: "37131",
    weapon2MainInfusion1Id: "37131",
    weapon1MainId: "46762",
    weapon1MainSigil1Id: "24658",
    weapon1MainSigil2Id: "24868",
    weapon2MainId: "46759",
    weapon2MainSigil1Id: "24868",
    weapon2OffId: "46777",
    weapon2OffSigilId: "24658",
    healId: "21762",
    utility1Id: "10546",
    utility2Id: "10607",
    utility3Id: "10622",
    eliteId: "10550"
  }, React.createElement(Warning, null, React.createElement(_components.p, null, "Remember to equip ", React.createElement(Item, {
    name: "serpentslaying",
    id: "24658"
  }), " + ", React.createElement(Item, {
    name: "impact",
    id: "24868"
  }), " for this encounter!")), React.createElement(Warning, null, React.createElement(_components.p, null, "The exploding adds after Ensolyss’ slam attack fully restore your life force, so auto attack in shroud, dodge the explosions at the very last moment and leave shroud mid dodge!"))), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Precast")), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Precast ", React.createElement(Skill, {
    name: "Summon Bone Minions",
    id: "10541"
  }), ", detonate them in a fire field"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Precast ", React.createElement(Skill, {
    id: "12363",
    id: "12363"
  }), " / Mistfire Wolf, etc (if you have it)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Take ", React.createElement(_components.em, null, "Mistlock Singularity")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Cast ", React.createElement(Skill, {
    name: "Lich Form",
    id: "10550"
  }), " 4 at the orb"), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Phase 1")), "\n", React.createElement(_components.p, null, "If you run ", React.createElement(Skill, {
    name: "Summon Flesh Golem",
    id: "10646"
  }), " start on greatsword and use the golem so that the charge hits ", React.createElement(_components.em, null, "ENSOLYSS"), " as the invulnerability disappearsIf you do not run ", React.createElement(Skill, {
    name: "Summon Flesh Golem",
    id: "10646"
  }), " start on axe/warhorn and use ", React.createElement(Skill, {
    name: "Wail of Doom",
    id: "10556"
  }), " (warhorn 4) as the invulnerability disappears and weapon swap"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Cast your healing signet into the invulnerability"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "precast ", React.createElement(Skill, {
    name: "Grasping Darkness",
    id: "29740"
  }), " (GS 5) so it lands into the brief cc window"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Nightfall",
    id: "29855"
  }), " (GS 4)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Weapon Swap"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Wail of Doom",
    id: "10556"
  }), " (Warhorn 4) so it lands into the brief cc window"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Place both of your wells"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Soul Spiral",
    id: "30504"
  }), " (Shroud 4)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Shroud auto attack until you are at ~6k Life force"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Use ", React.createElement(Skill, {
    name: "Gravedigger",
    id: "30163"
  }), " (GS 2)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Death Spiral",
    id: "30860"
  }), " (GS 3)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Auto attack ", React.createElement(_components.strong, null, "until he charges")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Use ", React.createElement(Skill, {
    name: "Signet of Vampirism",
    id: "21762"
  }), " (Heal) ", React.createElement(_components.strong, null, "during his charge")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Grasping Darkness",
    id: "29740"
  }), " (GS 5)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Nightfall",
    id: "29855"
  }), " (GS 4)1) Weapon swap2) ", React.createElement(Skill, {
    name: "Wail of Doom",
    id: "10556"
  }), " (Warhorn 4) to help with CC, if needed3) ", React.createElement(Skill, {
    name: "Ghastly Claws",
    id: "10528"
  }), " (Axe 2)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Place both of your wells"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "help to CC ", React.createElement(_components.em, null, "Ensolyss"), " if a break bar is up:"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Infusing Terror",
    id: "29958"
  }), " (Shroud 3)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Executioners Scythe",
    id: "30557"
  }), " (Shroud 5)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Soul Spiral",
    id: "30504"
  }), " (Shroud 4)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Auto attack"), "\n"), "\n"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Exit shroud on last few % until phase"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Ghastly Claws",
    id: "10528"
  }), " (Axe 2) to refill life force"), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Phase 2")), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Use ", React.createElement(Skill, {
    name: "Signet of Vampirism",
    id: "21762"
  }), " (Heal) to get back Scholar faster"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Locust Swarm",
    id: "10557"
  }), " (Warhorn 5)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Ghastly Claws",
    id: "10528"
  }), " (Axe 2)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Wail of Doom",
    id: "10556"
  }), " (Warhorn 4) to help with CC"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Weapon Swap"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Grasping Darkness",
    id: "29740"
  }), " (GS 5)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Nightfall",
    id: "29855"
  }), " (GS 4)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Place both of your wells"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Soul Spiral",
    id: "30504"
  }), " (Shroud 4)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Auto attack until ~6k life force"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "continue rotation"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.em, null, "Ensolyss"), " will charge, ", React.createElement(Skill, {
    name: "Signet of Vampirism",
    id: "21762"
  }), " (Heal) ", React.createElement(_components.strong, null, "during his charge")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Grasping Darkness",
    id: "29740"
  }), " (GS 5)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Nightfall",
    id: "29855"
  }), " (GS 4)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Place both of your wells"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Soul Spiral",
    id: "30504"
  }), " (Shroud 4)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Spam ", React.createElement(Skill, {
    name: "Gravedigger",
    id: "30163"
  }), " until ", React.createElement(_components.em, null, "Ensolyss"), " phases"), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Phase 3")), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Use ", React.createElement(Skill, {
    name: "Signet of Vampirism",
    id: "21762"
  }), " (Heal) to get back Scholar faster"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Locust Swarm",
    id: "10557"
  }), " (Warhorn 5)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Wail of Doom",
    id: "10556"
  }), " (Warhorn 4) to help with CC"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Continue rotation"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "For the last 15% stay in shroud until you have no life force left"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "If ", React.createElement(_components.em, null, "Ensolyss"), " isn’t dead by then, place wells and spam ", React.createElement(Skill, {
    name: "Gravedigger",
    id: "30163"
  })), "\n"), "\n"), "\n", React.createElement(_components.hr), "\n", React.createElement(Boss, {
    name: "skorvald",
    video: "",
    videoCreator: "",
    foodId: "91805",
    utilityId: "9443",
    heal: "signetofvampirism",
    utility1: "wellofsuffering",
    utility2: "wellofdarkness",
    utility3: "signetofspite",
    elite: "lichform",
    weapon1MainAffix: "Berserker",
    weapon1MainType: "Greatsword",
    weapon1MainSigil1: "force",
    weapon1MainSigil2: "Impact",
    weapon1MainInfusion1Id: "37131",
    weapon1MainInfusion2Id: "37131",
    weapon2OffAffix: "Berserker",
    weapon2OffType: "warhorn",
    weapon2OffSigil: "force",
    weapon2OffInfusionId: "37131",
    weapon2MainAffix: "Berserker",
    weapon2MainType: "axe",
    weapon2MainSigil1: "Impact",
    weapon2MainInfusion1Id: "37131",
    foodId: "91805",
    utilityId: "9443",
    weapon1MainInfusion1Id: "37131",
    weapon1MainInfusion2Id: "37131",
    weapon2OffInfusionId: "37131",
    weapon2MainInfusion1Id: "37131",
    weapon1MainId: "46762",
    weapon1MainSigil1Id: "24615",
    weapon1MainSigil2Id: "24868",
    weapon2MainId: "46759",
    weapon2MainSigil1Id: "24868",
    weapon2OffId: "46777",
    weapon2OffSigilId: "24615",
    healId: "21762",
    utility1Id: "10546",
    utility2Id: "10607",
    utility3Id: "10622",
    eliteId: "10550"
  }, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Skorvald has one of the biggest CC bars in CMs (2200). Make sure to use your CC skills!"), "\n")), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Precast")), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Precast Staff symbols on the orb that starts the encounter"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Precast ", React.createElement(Skill, {
    name: "Summon Bone Minions",
    id: "10541"
  }), ", detonate them in a fire field"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Cast ", React.createElement(Skill, {
    name: "Lich Form",
    id: "10550"
  }), " 4"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Take ", React.createElement(_components.em, null, "Mistlock Singularity")), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Phase 1")), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Cast ", React.createElement(Skill, {
    name: "Lich Form",
    id: "10550"
  }), " 4 before ", React.createElement(_components.em, null, "Skorvald"), " starts"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "if your group is really fast, keep Lich Form for the last phase"), "\n"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Grasping Darkness",
    id: "29740"
  }), " (GS 5)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Nightfall",
    id: "29855"
  }), " (GS 4)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Place both of your wells"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "If ", React.createElement(_components.em, null, "Skorvald"), " is still not broken:2) ", React.createElement(Skill, {
    name: "Infusing Terror",
    id: "29958"
  }), " (Shroud 3)"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Executioners Scythe",
    id: "30557"
  }), " (Shroud 5) 6) ", React.createElement(Skill, {
    name: "Soul Spiral",
    id: "30504"
  }), " (Shroud 4)"), "\n"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Auto attack until ", React.createElement(_components.em, null, "Skorvald"), " phases"), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.em, null, "Split Phase 1")), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "First Island"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Nightfall",
    id: "29855"
  }), " (GS 4)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Death Spiral",
    id: "30860"
  }), " (GS 3)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Gravedigger",
    id: "30163"
  }), " (GS 2)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Auto attack until add dies"), "\n"), "\n"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Second Island"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Soul Spiral",
    id: "30504"
  }), " (Shroud 4) after add evades attacks"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Shroud auto attack until add dies"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Deaths Charge",
    id: "30825"
  }), " (Shroud 2) into the updraft"), "\n"), "\n"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Third island"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Death Spiral",
    id: "30860"
  }), " (GS 3)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Gravedigger",
    id: "30163"
  }), " (GS 2)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Auto attack until add dies"), "\n"), "\n"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Fourth island"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Death Spiral",
    id: "30860"
  }), " (GS 3)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Gravedigger",
    id: "30163"
  }), " (GS 2)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Auto attack until add dies, take portal back"), "\n"), "\n"), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Phase 2")), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Grasping Darkness",
    id: "29740"
  }), " (GS 5)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Nightfall",
    id: "29855"
  }), " (GS 4)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Place both of your wells"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Soul Spiral",
    id: "30504"
  }), " (Shroud 4)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Weapon swap (if ", React.createElement(_components.em, null, "Skorvald"), " is not phased)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Continue attacking during his charges"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Use ", React.createElement(Skill, {
    name: "Signet of Vampirism",
    id: "21762"
  }), " (Heal) after the third charge"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "When breakbar is up: ", React.createElement(Skill, {
    name: "Wail of Doom",
    id: "10556"
  }), " (Warhorn 4) to help with CC"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Grasping Darkness",
    id: "29740"
  }), " (GS 5)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Nightfall",
    id: "29855"
  }), " (GS 4)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Soul Spiral",
    id: "30504"
  }), " (Shroud 4)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Shroud auto attack until ", React.createElement(_components.em, null, "Skorvald"), " phases"), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.em, null, "Split Phase 2")), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "First Island"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Nightfall",
    id: "29855"
  }), " (GS 4)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Death Spiral",
    id: "30860"
  }), " (GS 3)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Gravedigger",
    id: "30163"
  }), " (GS 2)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Auto attack until add dies"), "\n"), "\n"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Second Island"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Soul Spiral",
    id: "30504"
  }), " (Shroud 4) after add evades attacks"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Shroud auto attack until add dies"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Deaths Charge",
    id: "30825"
  }), " (Shroud 2) into the updraft"), "\n"), "\n"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Third island"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Death Spiral",
    id: "30860"
  }), " (GS 3)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Gravedigger",
    id: "30163"
  }), " (GS 2)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Auto attack until add dies"), "\n"), "\n"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Fourth island"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Gravedigger",
    id: "30163"
  }), " (GS 2)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Enter ", React.createElement(Skill, {
    name: "Lich Form",
    id: "10550"
  }), ", auto until 15%, take portal back"), "\n"), "\n"), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Phase 3")), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Cast ", React.createElement(Skill, {
    name: "Lich Form",
    id: "10550"
  }), " 4"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Grasping Darkness",
    id: "29740"
  }), " (GS 5)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Nightfall",
    id: "29855"
  }), " (GS 4)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Place both of your wells"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Soul Spiral",
    id: "30504"
  }), " (Shroud 4)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Shroud auto attack until ", React.createElement(_components.em, null, "Skorvald"), " dies"), "\n"), "\n"), "\n", React.createElement(_components.hr), "\n", React.createElement(Boss, {
    name: "artsariiv",
    video: "",
    videoCreator: "",
    foodId: "91805",
    utilityId: "9443",
    heal: "signetofvampirism",
    utility1: "wellofsuffering",
    utility2: "wellofdarkness",
    utility3: "signetofspite",
    elite: "lichform",
    weapon1MainAffix: "Berserker",
    weapon1MainType: "Greatsword",
    weapon1MainSigil1: "force",
    weapon1MainSigil2: "Impact",
    weapon1MainInfusion1Id: "37131",
    weapon1MainInfusion2Id: "37131",
    weapon2OffAffix: "Berserker",
    weapon2OffType: "warhorn",
    weapon2OffSigil: "force",
    weapon2OffInfusionId: "37131",
    weapon2MainAffix: "Berserker",
    weapon2MainType: "axe",
    weapon2MainSigil1: "Impact",
    weapon2MainInfusion1Id: "37131",
    foodId: "91805",
    utilityId: "9443",
    weapon1MainInfusion1Id: "37131",
    weapon1MainInfusion2Id: "37131",
    weapon2OffInfusionId: "37131",
    weapon2MainInfusion1Id: "37131",
    weapon1MainId: "46762",
    weapon1MainSigil1Id: "24615",
    weapon1MainSigil2Id: "24868",
    weapon2MainId: "46759",
    weapon2MainSigil1Id: "24868",
    weapon2OffId: "46777",
    weapon2OffSigilId: "24615",
    healId: "21762",
    utility1Id: "10546",
    utility2Id: "10607",
    utility3Id: "10622",
    eliteId: "10550"
  }), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Precast")), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Precast ", React.createElement(Skill, {
    name: "Summon Bone Minions",
    id: "10541"
  }), ", detonate them in a fire field"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Precast ", React.createElement(Skill, {
    id: "12363",
    id: "12363"
  }), " / Mistfire Wolf, etc (if you have it)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Take ", React.createElement(_components.em, null, "Mistlock Singularity")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Precast Staff symbols on the corner ", React.createElement(_components.em, null, "Artsariiv"), " goes to or on the 2 elites at start"), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Phase 1")), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Precast healing signet so it hits as she becomes vulnerable"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Death Spiral",
    id: "30860"
  }), " (GS 3)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Gravedigger",
    id: "30163"
  }), " (GS 2)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "enter ", React.createElement(Skill, {
    name: "Lich Form",
    id: "10550"
  })), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Special action key to ", React.createElement(_components.em, null, "Artsariiv")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "cast Lich Form 4"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Nightfall",
    id: "29855"
  }), " (GS4)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Weapon Swap"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Wail of Doom",
    id: "10556"
  }), " (Warhorn 4)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Place both of your wells"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "enter shroud"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "If not broken: ", React.createElement(Skill, {
    name: "Executioners Scythe",
    id: "30557"
  }), " (Shroud 5) and ", React.createElement(Skill, {
    name: "Infusing Terror",
    id: "29958"
  }), " (Shroud 3)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Souls Spiral"
  }), " (Shroud 4)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Shroud Auto Attacks until she phases or you reach ~6k life force"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Gravedigger",
    id: "30163"
  }), " (GS 2)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Death Spiral",
    id: "30860"
  }), " (GS 3)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Auto Attacks"), "\n"), "\n"), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.em, null, "Split Phase 1")), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Go to your marker/direction"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "CC the add with ", React.createElement(Skill, {
    name: "Grasping Darkness",
    id: "29740"
  }), " (GS5), ", React.createElement(Skill, {
    name: "Wail of Doom",
    id: "10556"
  }), " (Warhorn 4) and ", React.createElement(Skill, {
    name: "Executioners Scythe",
    id: "30557"
  }), " (Shroud 5)"), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Phase 2")), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Before she becomes vulnerable:"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Locust Swarm",
    id: "10557"
  }), " (Warhorn 5)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Signet of Vampirism",
    id: "21762"
  }), " (Heal)"), "\n"), "\n"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Soul Spiral",
    id: "30504"
  }), " (Shroud 4)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Ghastly Claws",
    id: "10528"
  }), " (Axe 2)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Weapon swap"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Gravedigger",
    id: "30163"
  }), " (GS 2)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Kill the anomaly, special action to ", React.createElement(_components.em, null, "Artsariiv")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Use both wells (hit the ", React.createElement(_components.em, null, "Elite"), " add too)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Nightfall",
    id: "29855"
  }), " (GS 4) (hit the ", React.createElement(_components.em, null, "Elite"), " add too)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Shroud auto attack"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Soul Spiral",
    id: "30504"
  }), " (Shroud 4)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Shroud auto attack until ~6k Life Force"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "If the boss isn't phased and health is 50% or lower:"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "Spam ", React.createElement(Skill, {
    name: "Gravedigger",
    id: "30163"
  }), " (GS 2)"), "\n"), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.em, null, "Split Phase 2")), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Go to your marker/direction"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "CC the adds"), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Phase 3")), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Gravedigger",
    id: "30163"
  }), " (GS 2)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "kill the anomaly"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Nightfall",
    id: "29855"
  }), " (GS 4) (hit the ", React.createElement(_components.em, null, "Elite"), " add too)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Place both of your wells (hit the ", React.createElement(_components.em, null, "Elite"), " add too)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Soul Spiral",
    id: "30504"
  }), " (Shroud 4)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Shroud auto attack until no life force left"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Soul Spiral",
    id: "30504"
  }), " (Shroud 4) if it gets off cooldown"), "\n"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Spam ", React.createElement(Skill, {
    name: "Gravedigger",
    id: "30163"
  }), " (GS 2)"), "\n"), "\n"), "\n", React.createElement(_components.hr), "\n", React.createElement(Boss, {
    name: "arkk",
    video: "",
    videoCreator: "",
    foodId: "91805",
    utilityId: "50082",
    heal: "signetofvampirism",
    utility1: "wellofsuffering",
    utility2: "wellofdarkness",
    utility3: "signetofspite",
    elite: "lichform",
    weapon1MainAffix: "Berserker",
    weapon1MainType: "Greatsword",
    weapon1MainSigil1: "force",
    weapon1MainSigil2: "Impact",
    weapon1MainInfusion1Id: "37131",
    weapon1MainInfusion2Id: "37131",
    weapon2OffAffix: "Berserker",
    weapon2OffType: "warhorn",
    weapon2OffSigil: "force",
    weapon2OffInfusionId: "37131",
    weapon2MainAffix: "Berserker",
    weapon2MainType: "axe",
    weapon2MainSigil1: "Impact",
    weapon2MainInfusion1Id: "37131",
    foodId: "91805",
    utilityId: "50082",
    weapon1MainInfusion1Id: "37131",
    weapon1MainInfusion2Id: "37131",
    weapon2OffInfusionId: "37131",
    weapon2MainInfusion1Id: "37131",
    weapon1MainId: "46762",
    weapon1MainSigil1Id: "24615",
    weapon1MainSigil2Id: "24868",
    weapon2MainId: "46759",
    weapon2MainSigil1Id: "24868",
    weapon2OffId: "46777",
    weapon2OffSigilId: "24615",
    healId: "21762",
    utility1Id: "10546",
    utility2Id: "10607",
    utility3Id: "10622",
    eliteId: "10550"
  }, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Place markers for the blooms"), "\n")), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Precast")), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Precast Staff symbols on the orb that starts the encounter"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Precast ", React.createElement(Skill, {
    name: "Summon Bone Minions",
    id: "10541"
  }), ", detonate them in a fire field"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Blast ", React.createElement(Boon, {
    name: "Might"
  }), " with ", React.createElement(SpecialActionKey, {
    name: "hypernovalaunch"
  })), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Cast ", React.createElement(Skill, {
    name: "Lich Form",
    id: "10550"
  }), " 4"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Take ", React.createElement(_components.em, null, "Mistlock Singularity")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Cast ", React.createElement(Skill, {
    name: "Lich Form",
    id: "10550"
  }), " 4 ", React.createElement(_components.strong, null, "again")), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Phase 1")), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Grasping Darkness",
    id: "29740"
  }), " (GS 4)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Nightfall",
    id: "29855"
  }), " (GS 4)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Place both of your wells"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Soul Spiral",
    id: "30504"
  }), " (Shroud 4)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Shroud auto attack until ", React.createElement(_components.em, null, "Arkk"), " phases ", React.createElement(_components.strong, null, "or")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "you are at ~6k Life force"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Use ", React.createElement(Skill, {
    name: "Gravedigger",
    id: "30163"
  }), " (GS 2)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Death Spiral",
    id: "30860"
  }), " (GS 3)"), "\n"), "\n"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Weapon swap"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "if ", React.createElement(_components.em, null, "Arkk"), " is still not phased ", React.createElement(Skill, {
    name: "Ghastly Claws",
    id: "10528"
  }), " (Axe 2)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Position yourself so that you can push the solar bloom with axe auto attacks into the pillar"), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Phase 2")), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Before ", React.createElement(_components.strong, null, "Arkk"), " becomes vulnerable:"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Locust Swarm",
    id: "10557"
  }), " (Warhorn 5)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Signet of Vampirism",
    id: "21762"
  }), " (Heal)"), "\n"), "\n"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Wail of Doom",
    id: "10556"
  }), " (Warhorn 4) to help with CC"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(SpecialActionKey, {
    name: "hypernovalaunch"
  }), " on ", React.createElement(_components.em, null, "Arkk"), " to help with CC"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Nightfall",
    id: "29855"
  }), " (GS 4)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Place both of your wells (if your group is relatively fast, do ", React.createElement(_components.em, null, "not"), " place wells here)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Soul Spiral",
    id: "30504"
  }), " (Shroud 4)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Shroud auto attack until ", React.createElement(_components.em, null, "Arkk"), " phases"), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.em, null, "Add Phase 1")), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Pull an add with ", React.createElement(Skill, {
    name: "Grasping Darkness",
    id: "29740"
  }), " (GS 5)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Death Spiral",
    id: "30860"
  }), " (GS 3)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Auto attack until ", React.createElement(_components.strong, null, "Arch Diviner"), " is 50%"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Spam ", React.createElement(Skill, {
    name: "Gravedigger",
    id: "30163"
  }), " (GS 2)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(SpecialActionKey, {
    name: "hypernovalaunch"
  }), " on the Elite to help with CC"), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Phase 3")), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Gravedigger",
    id: "30163"
  }), " (GS 2)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Death Spiral",
    id: "30860"
  }), " (GS 3)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Nightfall",
    id: "29855"
  }), " (GS4)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Place both of your wells"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Soul Spiral",
    id: "30504"
  }), " (Shroud 4)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Shroud auto attacks until ", React.createElement(_components.em, null, "Arkk"), " phases ", React.createElement(_components.strong, null, "or"), " you reach ~6k life force"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Use ", React.createElement(Skill, {
    name: "Gravedigger",
    id: "30163"
  }), " (GS 2)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Death Spiral",
    id: "30860"
  }), " (GS 3)"), "\n"), "\n"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Weapon swap"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Ghastly Claws",
    id: "10528"
  }), " (Axe 2)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Position yourself so that you can push the solar bloom with axe auto attacks into the pillar"), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Phase 4")), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Before ", React.createElement(_components.strong, null, "Arkk"), " becomes vulnerable:"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Locust Swarm",
    id: "10557"
  }), " (Warhorn 5)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Signet of Vampirism",
    id: "21762"
  }), " (Heal)"), "\n"), "\n"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Wail of Doom",
    id: "10556"
  }), " (Warhorn 4) to help with CC"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(SpecialActionKey, {
    name: "hypernovalaunch"
  }), " on ", React.createElement(_components.em, null, "Arkk"), " to help with CC"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Nightfall",
    id: "29855"
  }), " (GS 4)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Place both of your wells (if your group is relatively fast, do ", React.createElement(_components.em, null, "not"), " place wells here)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Soul Spiral",
    id: "30504"
  }), " (Shroud 4)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Shroud auto attack until ", React.createElement(_components.em, null, "Arkk"), " phases"), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.em, null, "Add Phase 2")), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Pull a golem (", React.createElement(_components.strong, null, "preferably the blue one so it doesn't knock you back"), ") with ", React.createElement(Skill, {
    name: "Grasping Darkness",
    id: "29740"
  }), " (GS 5)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Death Spiral",
    id: "30860"
  }), " (GS 3)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Auto attack until ", React.createElement(_components.strong, null, "Gladiator"), " is 50%"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Spam ", React.createElement(Skill, {
    name: "Gravedigger",
    id: "30163"
  }), " (GS 2)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(SpecialActionKey, {
    name: "hypernovalaunch"
  }), " on the Elite to help with CC"), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Phase 5")), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Death Spiral",
    id: "30860"
  }), " (GS 3)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Gravedigger",
    id: "30163"
  }), " (GS 2)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Nightfall",
    id: "29855"
  }), " (GS4)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Place both of your wells"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Soul Spiral",
    id: "30504"
  }), " (Shroud 4)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Shroud auto attacks until ", React.createElement(_components.em, null, "Arkk"), " phases ", React.createElement(_components.strong, null, "or")), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "Use ", React.createElement(Skill, {
    name: "Gravedigger",
    id: "30163"
  }), " (GS 2)"), "\n"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Weapon swap"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Ghastly Claws",
    id: "10528"
  }), " (Axe 2)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Position yourself so that you can push the solar bloom with axe auto attacks into the pillar"), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Phase 6")), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "If you have ", React.createElement(Skill, {
    name: "Lich Form",
    id: "10550"
  }), " off cooldown, use ", React.createElement(Skill, {
    name: "Lich Form",
    id: "10550"
  }), " 4 after you have pushed your solar bloom into the pillar"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Before ", React.createElement(_components.em, null, "Skorvald"), " becomes vulnerable:"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Locust Swarm",
    id: "10557"
  }), " (Warhorn 5)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Signet of Vampirism",
    id: "21762"
  }), " (Heal)"), "\n"), "\n"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "help with CC"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Wail of Doom",
    id: "10556"
  }), " (Warhorn 4) to help with CC"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(SpecialActionKey, {
    name: "hypernovalaunch"
  }), " on ", React.createElement(_components.em, null, "Arkk"), " to help with CC"), "\n"), "\n"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Grasping Darkness",
    id: "29740"
  }), " (GS 5)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Nightfall",
    id: "29855"
  }), " (GS 4)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Place both of your wells7 ", React.createElement(Skill, {
    name: "Soul Spiral",
    id: "30504"
  }), " (Shroud 4)8 Shroud auto attack until ", React.createElement(_components.em, null, "Arkk"), " dead9 If not dead spam ", React.createElement(Skill, {
    name: "Gravedigger",
    id: "30163"
  }), " (GS 2)"), "\n"), "\n"), "\n", React.createElement(_components.p, null, "Annotation:"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "If possible make sure you use ", React.createElement(SpecialActionKey, {
    name: "hypernovalaunch"
  }), " to CC the adds"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "First add spawn AoE's that strips boons, make sure you avoid them"), "\n"), "\n"), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
